const fetchPositions = async (user, page, rowsPerPage) => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/positions/'+user+'?page='+page+'&pageSize='+rowsPerPage, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const fetchAllPositionTypes = async () => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/positions/types', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const createPosition = async (data) => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/positions', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const updatePosition = async (id, data) => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/positions/'+id, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const deletePosition = async (id) => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/positions/'+id, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const fetchPositionsOverall = async (user) => {
    let response;
    try {
        response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/positions/'+user+'/overall', {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        // Handle network errors or errors during parsing
        console.error(error.message);
    }

    if (response.status === 403) {
        // Redirect to the login page
        window.location.href = '/auth/login';
    }

    if (!response.ok) {
        // Handle error status
        const errorData = await response.json();
        throw new Error(errorData.message || 'Unknown error');
    }

    // If response is OK, proceed with the data
    return await response.json()
};

const positionAPI = {
    fetchPositions,
    fetchAllPositionTypes,
    createPosition,
    updatePosition,
    deletePosition,
    fetchPositionsOverall
};

export default positionAPI;