import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/system";
import { useAuth } from "../hooks/useAuth";
import { Avatar, Button, Paper, Stack, SvgIcon, TextField, Typography, useTheme } from "@mui/material";
import CampaignIcon from '@mui/icons-material/Campaign';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import bitcoinAPI from "../services/bitcoinAPI";

export default function Settings() {
    const { user } = useAuth();
    const theme = useTheme();
    const [bitcoinPrice, setBitcoinPrice] = useState({});
    const [walletBalance, setWalletBalance] = useState({});

    useEffect(() => {
        fetchBitcoinPrice();
        fetchWalletBalance();
    }, []);

    const fetchBitcoinPrice = async () => {
        const data = await bitcoinAPI.fetchLatestPrice();
        setBitcoinPrice(data);
    };

    const fetchWalletBalance = async () => {
        const data = await bitcoinAPI.fetchLatestWalletBalance();
        setWalletBalance(data);
    };

    // Format the Bitcoin price with commas
    const formattedBitcoinPrice = (price, currency) => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,  // Hide decimal points
        maximumFractionDigits: 0,  // Hide decimal points
    }).format(price);

    // Convert the bitcoinPrice.Time to a Date object and format it
    const formatTime = (time) => {
        return time ? new Date(time).toLocaleString('en-GB', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        }) : 'N/A';
    }

    return (
        <>
            <Helmet>
                <title>Settings</title>
            </Helmet>

            <Container maxWidth="xl">
                <Stack spacing={2} direction="column" sx={{ justifyContent: "center", alignItems: "center" }}>
                    
                    <Typography variant="h5">
                        Settings
                    </Typography>

                    <Stack spacing={2} direction="row">
                        <Typography variant="h6">
                            Bitcoin Price USD: {formattedBitcoinPrice(bitcoinPrice.BitcoinPriceUSD, 'USD')}
                        </Typography>
                    </Stack>

                    <Stack spacing={2} direction="row">
                        <Typography variant="h6">
                            Bitcoin Price EUR: {formattedBitcoinPrice(bitcoinPrice.BitcoinPriceEUR, 'EUR')}
                        </Typography>
                    </Stack>

                    <Stack spacing={2} direction="row">
                        <Typography variant="h7">
                            Latest Bitcoin Price Fetch: {formatTime(bitcoinPrice.Time)}
                        </Typography>
                    </Stack>

                    <Stack spacing={2} direction="row">
                        <Typography variant="h6">
                            Wallet Balance: {walletBalance.WalletBalance}
                        </Typography>
                    </Stack>

                    <Stack spacing={2} direction="row">
                        <Typography variant="h7">
                            Latest Wallet Balance Fetch (check): {formatTime(walletBalance.Time)}
                        </Typography>
                    </Stack>

                </Stack>
            </Container>
        </>
    );
}