import { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/system";
import { useAuth } from "../hooks/useAuth";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, SvgIcon, TextField, Typography, useTheme } from "@mui/material";
import CampaignIcon from '@mui/icons-material/Campaign';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { useLocation } from 'react-router-dom';
import { capitalize } from 'lodash';
import investmentAPI from "../services/investmentAPI";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@mui/material';
import positionAPI from "../services/positionAPI";
import assetAPI from "../services/assetAPI";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Inputs() {
    const { user } = useAuth();
    const admin = "roccodonnarumma@gmail.com"
    const theme = useTheme();
    const location = useLocation();
    const [assets, setAssets] = useState([]);
    const [overallInvestments, setOverallInvestments] = useState([]);
    const [overallPositions, setOverallPositions] = useState([]);

    const removeLeadingSlashAndCapitalize = (path) => {
        return capitalize(path.replace(/^\//, ''));
    };

    const title = useMemo(() => {
        return removeLeadingSlashAndCapitalize(location.pathname);
    }, [location.pathname]);

    const fetchAssets = async () => {
        const data = await assetAPI.fetchAssets();
        setAssets(data);
    };

    const fetchOverallInvestments = async () => {
        const data = await investmentAPI.fetchInvestmentsOverall(title);
        console.log(data);
        setOverallInvestments(data);
    };

    const fetchOverallPositions = async () => {
        const data = await positionAPI.fetchPositionsOverall(title);
        setOverallPositions(data);
    };

    // investments
    const [investmentPage, setInvestmentPage] = useState(0);
    const [investmentRowsPerPage, setInvestmentRowsPerPage] = useState(10);
    const [investmentCount, setInvestmentCount] = useState(0);
    const [investments, setInvestments] = useState([]);
    const [investmentUsers, setInvestmentUsers] = useState([]);
    const [investmentTypes, setInvestmentTypes] = useState([]);
    const [newInvestment, setNewInvestment] = useState({
        User: "",
        Time: new Date(),
        Value: 0,
        Currency: "",
        Type: "",
    });
    const [openInvestmentModal, setOpenInvestmentModal] = useState(false);
    const [editInvestment, setEditInvestment] = useState({
        User: "",
        Time: new Date(),
        Value: 0,
        Currency: "",
        Type: "",
    });
    const [openEditInvestmentModal, setOpenEditInvestmentModal] = useState(false);
    const [openDeleteInvestmentModal, setOpenDeleteInvestmentModal] = useState(false);
    const [investmentToDelete, setInvestmentToDelete] = useState(null);

    const fetchInvestments = async () => {
        const data = await investmentAPI.fetchInvestments(title, investmentPage, investmentRowsPerPage);
        setInvestments(data);
        setInvestmentCount(data.Total);
    };

    const fetchInvestmentUsers = async () => {
        const data = await investmentAPI.fetchAllInvestmentUsers();
        setInvestmentUsers(data);
    };

    const fetchInvestmentTypes = async () => {
        const data = await investmentAPI.fetchAllInvestmentTypes();
        setInvestmentTypes(data);
    };

    const handleInvestmentChangePage = (newPage) => {
        setInvestmentPage(newPage);
    };

    const handleInvestmentChangeRowsPerPage = (newRowsPerPage) => {
        setInvestmentRowsPerPage(newRowsPerPage);
        setInvestmentPage(0);
    };

    const handleOpenInvestmentModal = () => {
        setOpenInvestmentModal(true);
    };

    const handleCloseInvestmentModal = () => {
        setOpenInvestmentModal(false);
    };

    const handleInvestmentInputChange = (e) => {
        const { name, value } = e.target;
        setNewInvestment({ ...newInvestment, [name]: value });
    };

    const handleInvestmentSubmit = async () => {
        const date = new Date(newInvestment.Time);
        const data = {
            User: newInvestment.User,
            Time: date.toISOString(),
            Value: parseFloat(newInvestment.Value),
            Currency: newInvestment.Currency,
            Type: newInvestment.Type
        };
        console.log(data);
        await investmentAPI.createInvestment(data);
        fetchInvestments(); // Refresh investments after creating a new one
        handleCloseInvestmentModal();
    };

    const handleOpenEditInvestmentModal = (investment) => {
        setEditInvestment(investment);
        setOpenEditInvestmentModal(true);
    };

    const handleUpdateInvestment = async () => {
        if (!editInvestment) return;

        const updatedData = {
            User: editInvestment.User,
            Time: new Date(editInvestment.Time).toISOString(),
            Value: parseFloat(editInvestment.Value),
            Currency: editInvestment.Currency,
            Type: editInvestment.Type
        };

        await investmentAPI.updateInvestment(editInvestment.ID, updatedData);
        fetchInvestments();  // Refresh the list after the update
        setOpenEditInvestmentModal(false);
    };

    const handleOpenDeleteInvestmentModal = (investment) => {
        setInvestmentToDelete(investment);
        setOpenDeleteInvestmentModal(true);
    };

    const handleDeleteInvestment = async () => {
        if (!investmentToDelete) return;

        await investmentAPI.deleteInvestment(investmentToDelete.ID);
        fetchInvestments();
        setOpenDeleteInvestmentModal(false);

    };

    // positions
    const [positionPage, setPositionPage] = useState(0);
    const [positionRowsPerPage, setPositionRowsPerPage] = useState(10);
    const [positionCount, setPositionCount] = useState(0);
    const [positions, setPositions] = useState([]);
    const [positionTypes, setPositionTypes] = useState([]);
    const [newPosition, setNewPosition] = useState({
        User: "",
        Time: new Date(),
        Asset: "",
        Value: 0,
        Type: "",
    });
    const [openPositionModal, setOpenPositionModal] = useState(false);
    const [editPosition, setEditPosition] = useState({
        User: "",
        Time: new Date(),
        Asset: "",
        Value: 0,
        Type: "",
    });
    const [openEditPositionModal, setOpenEditPositionModal] = useState(false);
    const [openDeletePositionModal, setOpenDeletePositionModal] = useState(false);
    const [positionToDelete, setPositionToDelete] = useState(null);

    const fetchPositions = async () => {
        const data = await positionAPI.fetchPositions(title, positionPage, positionRowsPerPage);
        setPositions(data);
        setPositionCount(data.Total);
    };

    const fetchPositionTypes = async () => {
        const data = await positionAPI.fetchAllPositionTypes();
        setPositionTypes(data);
    };

    const handlePositionChangePage = (newPage) => {
        setPositionPage(newPage);
    };

    const handlePositionChangeRowsPerPage = (newRowsPerPage) => {
        setPositionRowsPerPage(newRowsPerPage);
        setPositionPage(0);
    };

    const handleOpenPositionModal = () => {
        setOpenPositionModal(true);
    };

    const handleClosePositionModal = () => {
        setOpenPositionModal(false);
    };

    const handlePositionInputChange = (e) => {
        const { name, value } = e.target;
        setNewPosition({ ...newPosition, [name]: value });
    };

    const handlePositionSubmit = async () => {
        const date = new Date(newPosition.Time);
        const data = {
            User: newPosition.User,
            Time: date.toISOString(),
            Asset: newPosition.Asset,
            Value: parseFloat(newPosition.Value),
            Type: newPosition.Type
        };
        console.log(data);
        await positionAPI.createPosition(data);
        fetchPositions(); // Refresh positions after creating a new one
        handleClosePositionModal();
    };

    const handleOpenEditPositionModal = (position) => {
        setEditPosition(position);  // Store the position to be edited
        setOpenEditPositionModal(true);  // Open the modal
    };

    const handleUpdatePosition = async () => {
        if (!editPosition) return;

        const updatedData = {
            User: editPosition.User,
            Time: new Date(editPosition.Time).toISOString(),
            Asset: editPosition.Asset,
            Value: parseFloat(editPosition.Value),
            Type: editPosition.Type
        };

        await positionAPI.updatePosition(editPosition.ID, updatedData);
        fetchPositions();  // Refresh the list after the update
        setOpenEditPositionModal(false);
    };

    const handleOpenDeletePositionModal = (position) => {
        setPositionToDelete(position);
        setOpenDeletePositionModal(true);
    };

    const handleDeletePosition = async () => {
        if (!positionToDelete) return;

        await positionAPI.deletePosition(positionToDelete.ID);
        fetchPositions();
        setOpenDeletePositionModal(false);
    };

    useEffect(() => {
        fetchInvestments();
        fetchInvestmentUsers();
        fetchInvestmentTypes();
        fetchPositions();
        fetchPositionTypes();
        fetchAssets();
        fetchOverallInvestments();
        fetchOverallPositions();
    }, []);

    useEffect(() => {
        if (investmentUsers.length > 0) {
            const selectedUser = investmentUsers.find(user => user === title) || null;
            setNewInvestment((prev) => ({ ...prev, User: selectedUser }));
            setNewPosition((prev) => ({ ...prev, User: selectedUser }));
        }
    }, [investmentUsers]);

    const formatCurrency = (value, currency, maximumFractionDigits) => new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: maximumFractionDigits
    }).format(value);

    const formatPercentage = (value) => 
        new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);

    // Calculate total value across all positions
    const totalValue = overallPositions ? overallPositions.reduce((acc, row) => acc + row.TotalValueEUR, 0) : 0;

    const calculatePercentage = (invested, currentValue) => {
        return (currentValue - invested) / invested;
    };

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <Container maxWidth="xl">
                <Stack direction="horizontal" sx={{ mb: 4}}>
                    <Box
                        sx={{
                            height: 150,
                            backgroundColor: '#f0f0f0', // Light grey background
                            borderRadius: 2, // Rounded corners
                            boxShadow: 3, // Box shadow for depth
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            p: 2, // Padding inside the box
                        }}
                    >
                        <Typography variant="h6" color="textSecondary" sx={{ mb: 1 }}>
                            Invested
                        </Typography>
                        <Typography variant="h4" color="primary">
                            {formatCurrency(overallInvestments.Total, 'EUR', 0)}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            height: 150,
                            backgroundColor: '#f0f0f0', // Light grey background
                            borderRadius: 2, // Rounded corners
                            boxShadow: 3, // Box shadow for depth
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            p: 2, // Padding inside the box
                            ml: 4,
                        }}
                    >
                        <Typography variant="h6" color="textSecondary" sx={{ mb: 1 }}>
                            Networth
                        </Typography>
                        <Typography variant="h4" color="primary">
                            {formatCurrency(totalValue, 'EUR', 0)}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            height: 150,
                            backgroundColor: '#f0f0f0', // Light grey background
                            borderRadius: 2, // Rounded corners
                            boxShadow: 3, // Box shadow for depth
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            p: 2, // Padding inside the box
                            ml: 4,
                        }}
                    >
                        <Typography variant="h6" color="textSecondary" sx={{ mb: 1 }}>
                            Gain/Losses
                        </Typography>
                        <Typography variant="h4" color="primary">
                            {formatPercentage(calculatePercentage(overallInvestments.Total, totalValue))}
                        </Typography>
                    </Box>

                </Stack>

                <Typography variant="h4" sx={{ mb: 2 }}>
                    Networth
                </Typography>
                <TableContainer component={Paper} sx={{ mb: 4 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="summary table">
                        <TableHead>
                            <TableRow>
                                <TableCell>User</TableCell>
                                <TableCell>Asset</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Total Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {overallPositions && overallPositions.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.User}</TableCell>
                                    <TableCell>{row.Asset}</TableCell>
                                    <TableCell>{row.Total.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 10 })}</TableCell>
                                    <TableCell>{formatCurrency(row.TotalValueEUR, 'EUR', 2)}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={3} align="right"><strong>Total</strong></TableCell>
                                <TableCell>
                                    <strong>{formatCurrency(totalValue, 'EUR', 2)}</strong>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant="h4" sx={{ mb: 2 }}>
                    Investments
                </Typography>
                {user.Email == admin && (
                <Button variant="contained" color="primary" onClick={handleOpenInvestmentModal} sx={{ mb: 2 }}>
                    Create New Investment
                </Button>
                )}
                <Dialog open={openInvestmentModal} onClose={handleCloseInvestmentModal}>
                    <DialogTitle>Create New Investment</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth margin="dense">
                            <InputLabel>User</InputLabel>
                            <Select
                                label="User"
                                name="User"
                                value={newInvestment.User}
                                onChange={handleInvestmentInputChange}
                            >
                                {investmentUsers.map((user) => (
                                    <MenuItem key={user} value={user}>
                                        {user}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            label="Time"
                            name="Time"
                            type="date"
                            value={new Date(newInvestment.Time).toISOString().split('T')[0]}
                            onChange={(e) => {
                                const date = new Date(e.target.value);
                                setNewInvestment({ ...newInvestment, Time: date.toISOString() });
                            }}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            margin="dense"
                            label="Value"
                            name="Value"
                            type="number"
                            value={newInvestment.Value}
                            onChange={handleInvestmentInputChange}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            label="Currency"
                            name="Currency"
                            value={newInvestment.Currency}
                            onChange={handleInvestmentInputChange}
                            fullWidth
                        />
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Type</InputLabel>
                            <Select
                                label="Type"
                                name="Type"
                                value={newInvestment.Type}
                                onChange={handleInvestmentInputChange}
                            >
                                {investmentTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseInvestmentModal} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleInvestmentSubmit} color="primary">
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>

                <TableContainer component={Paper} sx={{ mb: 4 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>User</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Currency</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {investments && investments.Data && investments.Data.map((investment) => (
                                <TableRow key={investment.ID}>
                                    <TableCell>{investment.User}</TableCell>
                                    <TableCell>{new Date(investment.Time).toISOString().split('T')[0]}</TableCell>
                                    <TableCell>{investment.Value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 10 })}</TableCell>
                                    <TableCell>{investment.Currency}</TableCell>
                                    <TableCell>{investment.Type}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => handleOpenEditInvestmentModal(investment)}
                                            aria-label="edit"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => handleOpenDeleteInvestmentModal(investment)}
                                            aria-label="delete"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={investmentCount}
                    rowsPerPage={investmentRowsPerPage}
                    page={investmentPage}
                    onPageChange={handleInvestmentChangePage}
                    onRowsPerPageChange={handleInvestmentChangeRowsPerPage}
                />

                <Typography variant="h4" sx={{ mb: 2 }}>
                    Positions
                </Typography> 
                {user.Email == admin && (           
                <Button variant="contained" color="primary" onClick={handleOpenPositionModal} sx={{ mb: 2 }}>
                    Create New Position
                </Button>
                )}
                <Dialog open={openPositionModal} onClose={handleClosePositionModal}>
                    <DialogTitle>Create New Position</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth margin="dense">
                            <InputLabel>User</InputLabel>
                            <Select
                                label="User"
                                name="User"
                                value={newPosition.User}
                                onChange={handlePositionInputChange}
                            >
                                {investmentUsers.map((user) => (
                                    <MenuItem key={user} value={user}>
                                        {user}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            label="Time"
                            name="Time"
                            type="date"
                            value={new Date(newPosition.Time).toISOString().split('T')[0]}
                            onChange={(e) => {
                                const date = new Date(e.target.value);
                                setNewPosition({ ...newPosition, Time: date.toISOString() });
                            }}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Asset</InputLabel>
                            <Select
                                label="Asset"
                                name="Asset"
                                value={newPosition.Asset}
                                onChange={handlePositionInputChange}
                            >
                                {assets.map((asset) => (
                                    <MenuItem key={asset} value={asset}>
                                        {asset}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            label="Value"
                            name="Value"
                            type="number"
                            value={newPosition.Value}
                            onChange={handlePositionInputChange}
                            fullWidth
                        />
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Type</InputLabel>
                            <Select
                                label="Type"
                                name="Type"
                                value={newPosition.Type}
                                onChange={handlePositionInputChange}
                            >
                                {positionTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePositionModal} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handlePositionSubmit} color="primary">
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>User</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell>Asset</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {positions && positions.Data && positions.Data.map((position) => (
                                <TableRow key={position.ID}>
                                    <TableCell>{position.User}</TableCell>
                                    <TableCell>{new Date(position.Time).toISOString().split('T')[0]}</TableCell>
                                    <TableCell>{position.Asset}</TableCell>
                                    <TableCell>{position.Value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 10 })}</TableCell>
                                    <TableCell>{position.Type}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => handleOpenEditPositionModal(position)}
                                            aria-label="edit"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => handleOpenDeletePositionModal(position)}  // Opens delete modal
                                            aria-label="delete"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={positionCount}
                    rowsPerPage={positionRowsPerPage}
                    page={positionPage}
                    onPageChange={handlePositionChangePage}
                    onRowsPerPageChange={handlePositionChangeRowsPerPage}
                />


                <Dialog open={openEditInvestmentModal} onClose={() => setOpenEditInvestmentModal(false)}>
                    <DialogTitle>Edit Investment</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth margin="dense">
                            <InputLabel>User</InputLabel>
                            <Select
                                label="User"
                                name="User"
                                value={editInvestment?.User || ""}
                                onChange={(e) => setEditInvestment({ ...editInvestment, User: e.target.value })}
                            >
                                {investmentUsers.map((user) => (
                                    <MenuItem key={user} value={user}>
                                        {user}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            label="Time"
                            name="Time"
                            type="date"
                            value={new Date(editInvestment?.Time).toISOString().split('T')[0]}
                            onChange={(e) => {
                                const date = new Date(e.target.value);
                                setEditInvestment({ ...editInvestment, Time: date.toISOString() });
                            }}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            margin="dense"
                            label="Value"
                            name="Value"
                            type="number"
                            value={editInvestment?.Value || ""}
                            onChange={(e) => setEditInvestment({ ...editInvestment, Value: e.target.value })}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            label="Currency"
                            name="Currency"
                            value={editInvestment.Currency}
                            onChange={(e) => setEditInvestment({ ...editInvestment, Currency: e.target.value })}
                            fullWidth
                        />
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Type</InputLabel>
                            <Select
                                label="Type"
                                name="Type"
                                value={editInvestment.Type}
                                onChange={(e) => setEditInvestment({ ...editInvestment, Type: e.target.value })}
                            >
                                {investmentTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenEditInvestmentModal(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleUpdateInvestment} color="primary">
                            Save Changes
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={openEditPositionModal} onClose={() => setOpenEditPositionModal(false)}>
                    <DialogTitle>Edit Position</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth margin="dense">
                            <InputLabel>User</InputLabel>
                            <Select
                                label="User"
                                name="User"
                                value={editPosition.User}
                                onChange={(e) => setEditPosition({ ...editPosition, User: e.target.value })}
                            >
                                {investmentUsers.map((user) => (
                                    <MenuItem key={user} value={user}>
                                        {user}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            label="Time"
                            name="Time"
                            type="date"
                            value={new Date(editPosition.Time).toISOString().split('T')[0]}
                            onChange={(e) => {
                                const date = new Date(e.target.value);
                                setEditPosition({ ...editPosition, Time: date.toISOString() });
                            }}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Asset</InputLabel>
                            <Select
                                label="Asset"
                                name="Asset"
                                value={editPosition.Asset}
                                onChange={(e) => setEditPosition({ ...editPosition, Asset: e.target.value })}
                            >
                                {assets.map((asset) => (
                                    <MenuItem key={asset} value={asset}>
                                        {asset}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            margin="dense"
                            label="Value"
                            name="Value"
                            type="number"
                            value={editPosition.Value}
                            onChange={(e) => setEditPosition({ ...editPosition, Value: e.target.value })}
                            fullWidth
                        />
                        <FormControl fullWidth margin="dense">
                            <InputLabel>Type</InputLabel>
                            <Select
                                label="Type"
                                name="Type"
                                value={editPosition.Type}
                                onChange={(e) => setEditPosition({ ...editPosition, Type: e.target.value })}
                            >
                                {positionTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenEditPositionModal(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleUpdatePosition} color="primary">
                            Save Changes
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={openDeleteInvestmentModal} onClose={() => setOpenDeleteInvestmentModal(false)}>
                    <DialogTitle>Delete Investment</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete the investment in "{investmentToDelete?.Currency}" with a value of {investmentToDelete?.Value}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDeleteInvestmentModal(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeleteInvestment} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={openDeletePositionModal} onClose={() => setOpenDeletePositionModal(false)}>
                    <DialogTitle>Delete Position</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to delete the position "{positionToDelete?.Asset}" with a value of {positionToDelete?.Value}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDeletePositionModal(false)} color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleDeletePosition} color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
}